import { defineStore } from "pinia";

export const useShippingStore = defineStore("shipping", {
  state: () => {
    return {
      isHaveInputedStep1: false,
      isHaveInputedStep2: false,
      isCODAvailable: false,
      listNotSupportCOD: [] as String[],
      listSupportCOD: [] as String[],
      shipment: {
        origin: {
          latitude: -6.3031123,
          longitude: 106.7794934999,
          postal_code: "",
          name: "",
          phone: "",
          email: "",
          address: "",
          note: "",
        },
        merchant_name: "",
        courier_service_id: "",
        shipment_type: "",
        pickup_time: "",
      } as iShipment,
      courierName: false,
      step1: {
        vehince: "",
        pickup: "",
        type: "",
        warehouse: {
          id: "",
          name: "",
          address_1: "",
          postal_code: "",
          state_province: "",
          city: "",
        },
        courier: "",
        service: "",
        time: {
          id: "",
          day: "",
          date: "",
          pickup_time: "",
        },
      } as IShippingStep1,
      step2: [] as ordersShipping[],
      orders: [] as ordersShipping[],
      shippingAmounts: [] as Number[],
      shipment_id: null,
      platform_fee: 0,
      total_shipping: 0,
      isPayNow: false,
      isFromLead: false,
    };
  },
  actions: {
    resetShipping() {
      this.$patch({
        isHaveInputedStep1: false,
        isHaveInputedStep2: false,
        isCODAvailable: false,
        listNotSupportCOD: [],
        listSupportCOD: [],
        shipment: {
          origin: {
            latitude: -6.3031123,
            longitude: 106.7794934999,
            postal_code: "",
            name: "",
            phone: "",
            email: "",
            address: "",
            note: "",
          },
          merchant_name: "",
          courier_service_id: "",
          shipment_type: "",
          pickup_time: "",
        },
        step1: {
          vehince: "",
          pickup: "",
          type: "",
          warehouse: {
            id: "",
            name: "",
            address_1: "",
            postal_code: "",
            state_province: "",
            city: "",
          },
          courier: "",
          service: "",
          time: {
            id: "",
            day: "",
            date: "",
            pickup_time: "",
          },
        },
        step2: [],
        courierName: "",
        orders: [],
        shippingAmounts: [],
        platform_fee: 0,
        total_shipping: 0,
        isPayNow: false,
        isFromLead: false,
      });
    },
    set(params: any) {
      this.$patch({
        ...params,
      });
    },
  },
  persist: true,
});
